import React, { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import Form from "../../components/Form"
import Breadcrumbs from "../../components/Breadcrumbs/index"

import vkImage from "../../assets/images/nutrilon/vkAdvertising.png"
import baby from "../../assets/images/nutrilon/baby.png"
import likeImage from "../../assets/images/nutrilon/like.png"
import vkBigImage from "../../assets/images/nutrilon/vkBigImage.png"
import nutrilon1 from "../../assets/images/nutrilon/nutrilon1.png"
import nutrilon2 from "../../assets/images/nutrilon/nutrilon2.png"
import nutrilon3 from "../../assets/images/nutrilon/nutrilon3.png"
import * as styles from "./nutrlion.module.css"

const Nutrilon = ({ pageContext }) => {
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <div className={"container"}>
        <div className={styles.boxSectionHeader}>
          <Breadcrumbs pageContext={pageContext} title="Nutrilon" />
          <div className={styles.header}>
            <p className={styles.title}>~3,04</p>
            <p className={styles.desc}>
              ROAS И ВЫСОКИЕ ПОКАЗАТЕЛИ С ИСПОЛЬЗОВАНИЕМ VK РЕКЛАМЫ
            </p>
          </div>
          <div className={styles.boxVkAdvertising}>
            <p className={styles.title}>
              Провели performance-кампанию в VK Рекламе для детского молочного
              питания Nutrilon — одного из брендов компании Нутриция*.
            </p>
            <img src={vkImage} alt={vkImage} />
          </div>
          <div className={styles.nutriciaBox}>
            <p className={styles.title}>
              Компания “Нутриция”*, входящая в группу продовольственных компаний
              Danone, уже более 120 лет заботится о правильном питании малышей.{" "}
            </p>
            <p className={styles.desc}>
              Danone поддерживает глобальную рекомендацию ВОЗ по общественному
              здравоохранению, призывающую к исключительному грудному
              вскармливанию в течение первых шести месяцев жизни и продолжению
              грудного вскармливания до двух лет и более, в сочетании с
              безопасным введением соответствующих дополнительных продуктов.
              Политика Danone по маркетингу заменителей грудного молока
              опубликована на странице https://nutricia-medical.ru/contacts/.
            </p>
          </div>
          <div className={styles.boxImage}>
            <img src={baby} alt={baby} />
          </div>
          <div className={styles.sectionChallenges}>
            <div className={styles.itemChallenges}>
              <p className={styles.headerChallenges}>
                Перед нами стояли задачи
              </p>
              <p className={styles.descChallenges}>
                ✅ Удержание текущих покупателей, которые в ближайшее время
                будут переходить с одной ступени смеси на следующую{" "}
              </p>
              <p className={styles.descChallenges}>
                ✅ Рост продаж на всех ступенях молочных смесей{" "}
              </p>
              <p className={styles.descChallenges}>
                ✅ Расчёт отдачи от маркетинговых инвестиций по проведённой
                рекламной кампании
              </p>
            </div>
            <div className={styles.itemChallenges}>
              <p className={styles.headerChallenges}>Основная цель РК</p>
              <p className={styles.descChallenges}>
                Проверить гипотезы возможности выявления покупателей молочной
                смеси, которые в ближайшее время должны перейти на следующую
                ступень по мере роста ребёнка, и переключение покупателей с
                одной ступени на следующую для максимально длительного удержания
                клиентов с брендом Nutrilon.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blueBorderBox}>
        <div className={"container"}>
          <div className={styles.boxSectionHeader}>
            <div className={styles.sectionSolution}>
              <div className={styles.itemSolution}>
                <p className={styles.headerSolution}>Решение</p>
                <p className={styles.descSolution}>
                  Для формирования сегментов текущих покупателей молочных смесей
                  Nutrilon по каждой возрастной ступени мы использовали
                  собственную CDP-платформу, которая анализирует данные чеков
                  маркетплейсов и интернет-магазинов.{" "}
                </p>
                <div>
                  <p className={styles.descSolution}>
                    И далее, с использованием математических моделей,
                    учитывающих длительность периода потребления определенной
                    ступени смеси и анализируя состав корзины потребителей, мы
                    определили тех покупателей, которые находятся на завершающем
                    этапе покупки текущей ступени смеси и сформировали 2
                    сегмента покупателей:
                  </p>
                  <div className={styles.itemDescriptionNumber}>
                    <p className={styles.isMobile}>1.</p>
                    <p className={styles.descSolution}>
                      Текущие покупатели 1 ступени молочной смеси, которые в
                      ближайшее время будут переходить на 2-ю ступень
                    </p>
                  </div>
                  <div className={styles.itemDescriptionNumber}>
                    <p className={styles.isMobile}>2.</p>
                    <p className={styles.descSolution}>
                      Текущие покупатели 2 ступени молочной смеси, которые в
                      ближайшее время будут переходить на 3-ю ступень
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.sectionLikeSolution}>
                <div className={styles.itemSolutionLikeBox}>
                  <img src={likeImage} alt={likeImage} />
                  <p className={styles.descLikeSolution}>
                    Мы работали исключительно с той аудиторией, которая с
                    максимальной вероятностью подошла к моменту перехода и
                    купила бы следующую ступень в период поддержки.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blueVkSection}>
        <div className={"container"}>
          <div className={styles.boxSectionHeader}>
            <div className={styles.itemBigVk}>
              <img src={vkBigImage} alt={vkBigImage} />
              <p>
                В размещении задействовали основные инструменты VK реклама –
                социальные сети vk.ru, ok.ru и рекламная сеть mail.ru. Медийные
                показатели были на высоком уровне.
              </p>
            </div>
            <div className={styles.boxBigVk}>
              <div className={styles.creativesHeader}>
                <p>Креативы</p>
              </div>
              <div className={styles.boxCreativesImage}>
                <div>
                  <img src={nutrilon1} alt="" />
                </div>
                <div>
                  <img src={nutrilon2} alt="" />
                </div>
                <div>
                  <img src={nutrilon3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.blueVkSection}>
        <div className={"container"}>
          <div className={styles.boxSectionHeader}>
            <div className={styles.results}>
              <p className={styles.resultsHeader}>Результаты</p>
              <p className={styles.resultsDesc}>
                Медийные показатели были на высоком уровне: Средний показатель
                по кампании
              </p>
              <div className={styles.boxBoldResultBox}>
                <p>СTR - 0,83%</p>
                <p>ROAS - 3,04</p>
              </div>
              <div className={styles.resultBoxLastSection}>
                <p>
                  Наибольшее количество покупок следующей ступени получили от 1
                  сегмента — покупателей, переходящих на 2ую ступень, таким
                  образом захватив аудиторию на максимальный цикл продаж.
                </p>
                <p>
                  Мы увидели высокие результаты по итогам кампании с точки
                  зрения переключения на следующую ступень, выявив, что
                  performance-кампания также может использоваться для удержания
                  пользователей в течение периода потребления молочной смеси
                  ребёнком.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form t={t} />
    </Layout>
  )
}

export default Nutrilon

import React from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Breadcrumbs = ({ pageContext, title }) => {
  const { t, i18n } = useTranslation()

  if (i18n.language !== "ru") {
    delete pageContext.breadcrumb.crumbs[0]
  }

  if (title && pageContext?.breadcrumb?.crumbs?.length) {
    pageContext.breadcrumb.crumbs[
      pageContext.breadcrumb.crumbs.length - 1
    ].crumbLabel = t(title)
  }

  pageContext.breadcrumb.crumbs.map(crumb => t(crumb))

  return (
    <Breadcrumb
      crumbs={pageContext.breadcrumb.crumbs}
      crumbSeparator=""
      crumbLabel={pageContext?.crumbLabel}
    />
  )
}

export default Breadcrumbs

// extracted by mini-css-extract-plugin
export var blueBorderBox = "nutrlion-module--blueBorderBox--fe708";
export var blueVkSection = "nutrlion-module--blueVkSection--0ce52";
export var boxBigVk = "nutrlion-module--boxBigVk--817ac";
export var boxBoldResultBox = "nutrlion-module--boxBoldResultBox--3ffe5";
export var boxCreativesImage = "nutrlion-module--boxCreativesImage--2cb10";
export var boxFormFooter = "nutrlion-module--boxFormFooter--6f087";
export var boxFormFooterTextarea = "nutrlion-module--boxFormFooterTextarea--614e6";
export var boxImage = "nutrlion-module--boxImage--f7870";
export var boxNutrilonFooter = "nutrlion-module--boxNutrilonFooter--1e00f";
export var boxNutrilonFooterForm = "nutrlion-module--boxNutrilonFooterForm--c918e";
export var boxNutrilonFooterFormTextarea = "nutrlion-module--boxNutrilonFooterFormTextarea--37488";
export var boxNutrilonFooterHeader = "nutrlion-module--boxNutrilonFooterHeader--ed4d8";
export var boxSectionHeader = "nutrlion-module--boxSectionHeader--be838";
export var boxVkAdvertising = "nutrlion-module--boxVkAdvertising--b5a35";
export var checkmark = "nutrlion-module--checkmark--4af98";
export var creativesHeader = "nutrlion-module--creativesHeader--e31f1";
export var customCheckbox = "nutrlion-module--customCheckbox--b022c";
export var desc = "nutrlion-module--desc--1c7df";
export var descChallenges = "nutrlion-module--descChallenges--83303";
export var descLikeSolution = "nutrlion-module--descLikeSolution--63053";
export var descSolution = "nutrlion-module--descSolution--d0269";
export var disabledBreadcrumb = "nutrlion-module--disabledBreadcrumb--ccce9";
export var footerButton = "nutrlion-module--footerButton--16bf8";
export var footerCheckBox = "nutrlion-module--footerCheckBox--17022";
export var header = "nutrlion-module--header--d5327";
export var headerChallenges = "nutrlion-module--headerChallenges--214d6";
export var headerSolution = "nutrlion-module--headerSolution--de061";
export var isMobile = "nutrlion-module--isMobile--673cc";
export var itemBigVk = "nutrlion-module--itemBigVk--044a9";
export var itemChallenges = "nutrlion-module--itemChallenges--fe080";
export var itemDescriptionNumber = "nutrlion-module--itemDescriptionNumber--e4624";
export var itemSolution = "nutrlion-module--itemSolution--00fd5";
export var itemSolutionLikeBox = "nutrlion-module--itemSolutionLikeBox--b9c18";
export var nutriciaBox = "nutrlion-module--nutriciaBox--3d609";
export var nutrilonFooterDesc = "nutrlion-module--nutrilonFooterDesc--82ded";
export var nutrilonFooterHeader = "nutrlion-module--nutrilonFooterHeader--9ba6d";
export var resultBoxLastSection = "nutrlion-module--resultBoxLastSection--905ab";
export var results = "nutrlion-module--results--c010d";
export var resultsDesc = "nutrlion-module--resultsDesc--421a3";
export var resultsHeader = "nutrlion-module--resultsHeader--1c56c";
export var sectionChallenges = "nutrlion-module--sectionChallenges--94f33";
export var sectionLikeSolution = "nutrlion-module--sectionLikeSolution--573e5";
export var sectionNutrilonFooter = "nutrlion-module--sectionNutrilonFooter--319ac";
export var sectionSolution = "nutrlion-module--sectionSolution--cddb2";
export var smallInput = "nutrlion-module--smallInput--fc2f4";
export var textarea = "nutrlion-module--textarea--f7c17";
export var title = "nutrlion-module--title--631a7";